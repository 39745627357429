<template>
  <a-modal
    width="1234px"
    wrapClassName="modal-report"
    title="Noon Report"
    :visible="visible"
    @cancel="onCancel"
    :footer="null"
  >
    <div class="modal-container">
      <a-button type="link" @click="handleDownload">
        <img :src="icons.download" alt="icon" />
        Download
      </a-button>

      <div class="modal-body">
        <a-table
          :dataSource="dataSource"
          :columns="columns"
          :showHeader="false"
          :scroll="{ y: 'calc(100vh - 350px)' }"
          :pagination="false"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex === 'name'">
              <a-typography-text type="secondary">
                {{ record.name }}</a-typography-text
              >
            </template>
          </template>
        </a-table>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { defineComponent } from "vue";

import { icons, data } from "@/assets";

export default defineComponent({
  name: "ModalNoonReport",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
  },

  setup() {
    const handleDownload = () => {
      console.log("download");
    };

    return {
      icons,
      handleDownload,
      dataSource: Object.keys(data.noonReport).map((key) => ({
        name: data.map[key],
        value: data.noonReport[key],
        key,
      })),
      columns: [
        {
          dataIndex: "name",
          key: "name",
        },
        {
          dataIndex: "value",
          key: "value",
        },
      ],
    };
  },
});
</script>

<style lang="less" scoped>
.modal-container {
  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    position: absolute;
    top: 1rem;
    right: 6.4rem;
    gap: 0.8rem;
  }
}

.modal-body {
  padding: 2.2rem;
}
</style>