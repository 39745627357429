<template>
  <a-space align="center" v-if="+value !== 0 || showZero">
    <div :class="inverted ? 'icon-arrow-rotated' : 'icon-arrow'" v-if="+value !== 0">
      <img class="icon-arrow-img" :src="iconArrow" alt="arrow" />
    </div>

    <a-typography-text  v-if="+value === 0" >
      {{ formattedValue }}
    </a-typography-text>

    <a-typography-text :type="colorType" v-else >
      {{ formattedValue }}%
    </a-typography-text>

  </a-space>
</template>

<script>
import { defineComponent, computed } from "vue";

import { icons } from "@/assets";
import { kFormatter } from "@/helpers";

export default defineComponent({
  name: "PercentDirection",

  props: {
    value: {
      type: Number,
      default: 0,
    },
    inverted:{
      type: Boolean,
      default: false,
    },
    showZero: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const formattedValue = computed(() => kFormatter.format(+props.value));
    let isGreen = (props.inverted && props.value > 0) || (!props.inverted && props.value < 0);
    const iconArrow = computed(() => isGreen ? icons.arrowGreenDown : icons.arrowRedUp);
    const colorType = computed(() => isGreen  ? "success" : "danger");

    return {
      iconArrow,
      formattedValue,
      colorType,
    };
  },
});
</script>

<style lang="less" scoped>
.icon-arrow {
  display: flex;
  align-items: center;
  &-rotated{
    transform: rotate(180deg);
  }
}

.icon-arrow-img {
  height: 1.6rem;
}
</style>



  