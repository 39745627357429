const state = {
    list: [],
    details: {},
};

const getters = {};
const actions = {};

const mutations = {
    setList(state, data) {
        state.list = data;
    },
    setDetails(state, data) {
        state.details = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
