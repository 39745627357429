<template>
  <a-layout-header class="header">
    <div class="header-left">
      <HeaderLogotype />
      <div class="navigation">
        <div
          :class="[
            'navigation-item',
            { 'is-active': handleActiveCurrentRoute('/fip/overview', true) },
          ]"
        >
          <router-link class="navigation-link" :to="{ name: 'Overview' }"
            >KPIs</router-link
          >
        </div>

        <div
          :class="[
            'navigation-item',
            {
              'is-active':
                handleActiveCurrentRoute('/fip/fleet') &&
                !handleActiveCurrentRoute('/fip/benchmarking/fleet'),
            },
          ]"
        >
          <router-link class="navigation-link" :to="{ name: 'Fleet' }"
            >{{ $t('header.fleet') }}</router-link
          >
        </div>

        <div
          :class="[
            'navigation-item',
            {
              'is-active':
                handleActiveCurrentRoute('fip/benchmarking/fleet') ||
                handleActiveCurrentRoute('fip/benchmarking/ports'),
            },
          ]"
        >
          <router-link
            class="navigation-link"
            :to="{ name: 'Benchmarking', params: { tab: 'fleet' } }"
            >{{ $t('header.benchmarking') }}</router-link
          >
        </div>

        <div
          :class="[
            'navigation-item',
            {
              'is-active':
                handleActiveCurrentRoute('fip/history') ||
                handleActiveCurrentRoute('fip/voyage'),
            },
          ]"
        >
          <router-link class="navigation-link" :to="{ name: 'History' }"
            >{{ $t('header.history') }}</router-link
          >
        </div>

        <!-- <div
          :class="[
            'navigation-item',
            { 'is-active': handleActiveCurrentRoute('chart') },
          ]"
        >
          <router-link class="navigation-link" to="/chart">Chart</router-link>
        </div> -->
      </div>
    </div>

    <div class="header-right">
      <HeaderAccounts />

      <!-- <a href="" class="notifications"><BellOutlined /></a> -->

      <a-dropdown placement="bottomRight" :trigger="['click']">
        <a class="ant-dropdown-link" @click.prevent>
          <a-avatar style="background-color: #ee3440" :size="36">
            <template #icon>
              <UserOutlined />
            </template>
          </a-avatar>

          <DownOutlined />
        </a>

        <template #overlay>
          <a-menu>
            <a-menu-item key="0">
              <a href="" @click.prevent="handleSwitchToRu">Русский</a>
            </a-menu-item>
            <a-menu-item key="1">
              <a href="" @click.prevent="handleSwitchToEn">English</a>
            </a-menu-item>
            <a-menu-item key="2">
              <a href="" @click.prevent="handleLogout">{{$t('header.logout')}}</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </a-layout-header>
</template>

<script>
import { defineComponent, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
import useAuth from "@/composables/useAuth";
import { computed } from "vue";
import { useI18n } from 'vue-i18n'

import {
  DownOutlined,
  UserOutlined,
  // BellOutlined,
} from "@ant-design/icons-vue";

import HeaderLogotype from "./HeaderLogotype";
import HeaderAccounts from "./HeaderAccounts";


export default defineComponent({
  name: "Header",

  setup() {
    const { appContext } = getCurrentInstance();
    const route = useRoute();
    const auth = useAuth();
    const {locale} = useI18n({useScope: 'global'})
    const handleActiveCurrentRoute = (to, isExact) =>
      isExact ? route.path === to : route.path.includes(to);

    const handleLogout = async (body) => {
      auth.logout(body);
    };
    const handleSwitchToRu = () => {

      localStorage.setItem('lang', 'ru') 
      if (locale.value === 'ru')
        return;

      window.location.reload()
    }

    const handleSwitchToEn = () => {
      localStorage.setItem('lang', 'en')

      if (locale.value === 'en')
        return;
      
      window.location.reload()
    }

    const user = computed(() => auth.user());

    setTimeout(() => {
      if (appContext.config.globalProperties.$metrika) {
        appContext.config.globalProperties.$metrika.userParams({
          user_id: user.value.id,
          shipowner_id: user.value.shipowner.id,
        });
      }
    }, 1000);

    return {
      handleActiveCurrentRoute,
      handleLogout,
      handleSwitchToRu,
      handleSwitchToEn,
      user,
    };
  },

  components: {
    HeaderLogotype,
    HeaderAccounts,
    DownOutlined,
    UserOutlined,
    // BellOutlined,
  },
});
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  box-shadow: 0 0.2rem 0.4rem rgba(@color-dark, 0.1);
  background: @color-black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  &-left {
    display: flex;
    align-items: center;
  }

  &-right {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    padding-right: 2.4rem;

    .ant-dropdown-link {
      display: flex;
      align-items: center;
      gap: 0.8rem;

      &:not(:hover) {
        color: @color-secondary;
      }
    }
  }
}

.notifications {
  display: flex;
  align-items: center;
  font-size: 2.1rem;
  opacity: 0.5;

  &:not(:hover) {
    color: @color-secondary;
  }
}

.navigation {
  display: flex;
  align-items: center;
  gap: 3.2rem;
  margin-left: 18rem;

  &-item {
    letter-spacing: 0.05em;
    position: relative;

    &.is-active {
      .navigation-link {
        color: @color-primary-light;
      }

      &:before {
        content: "";
        width: 100%;
        height: 0.3rem;
        background-color: @color-primary;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  &-link {
    color: @color-white;
    text-transform: uppercase;

    &:hover {
      color: @color-primary-light;
    }
  }
}
</style>
