const state = {
  loading: false,
};

const getters = {};
const actions = {};

const mutations = {
  loadingStatus(state, status) {
    state.loading = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
