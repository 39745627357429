// import { xml2js } from 'xml2js';

// import {
//   ref,
// } from "vue";

// const RTZ_NAMESPACE = 'http://www.s63.org/RTZ';
// const MAX_WP_RADIUS = 5.0;
// const MAX_LEG_STD = 3.0;
// const MAX_LEG_SPEED = 30.0;

const parseString = require('xml2js').parseString
// export 
export const convertRtzRouteToGeoJson = (rtzRoute) => {    
    console.log('rtzRoute', rtzRoute);

    const parseXml = (xmlString) => new Promise((resolve, reject) => {
        parseString(xmlString, (err, result) => {
          if (err) {
            reject(err);
            console.log('err', err);
          } else {
            resolve(result);
            console.log('result', result);
          }
        });
      });
    

    return parseXml(rtzRoute).then((result) => {
      return ({
        type: "FeatureCollection",        
        properties: {
          routename: result.route.routeInfo[0].$.routeName
        },
        features: result.route.waypoints[0].waypoint.map((item) => (
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [ item.position[0].$.lon, item.position[0].$.lat]
            }
          })
          )
      })
    });    
  }

      // console.log("features", features);

    //   const { waypoints: { waypoint: waypoints } } = result[`${RTZ_NAMESPACE}:waypoints`];
    //   const { defaultWaypoint: defaultWaypoint } = result[`${RTZ_NAMESPACE}:waypoints`];
    //   const { schedule: schedule } = result[`${RTZ_NAMESPACE}:schedule`];

    //   // Extract default values
    //   let defaultRadius = null;
    //   let defaultPortXte = null;
    //   let defaultStbdXte = null;

    //   if (defaultWaypoint) {
    //     const {
    //       $: { radius: defaultRadiusStr, portsideXTD: defaultPortXteStr, starboardXTD: defaultStbdXteStr },
    //     } = defaultWaypoint;
    //     defaultRadius = parseFloat(defaultRadiusStr);
    //     defaultPortXte = parseFloat(defaultPortXteStr);
    //     defaultStbdXte = parseFloat(defaultStbdXteStr);
    //   }

    //   waypoints.forEach((waypoint) => {
    //     const {
    //       $: { id: wpId, radius: wpRadiusStr, portsideXTD: wpPortXteStr, starboardXTD: wpStbdXteStr },
    //       leg: wpLeg,
    //     } = waypoint;

    //     // Extract waypoint data
    //     const wpRadius = parseFloat(wpRadiusStr) || defaultRadius;
    //     const wpPortXte = parseFloat(wpPortXteStr) || defaultPortXte;
    //     const wpStbdXte = parseFloat(wpStbdXteStr) || defaultStbdXte;

    //     // Extract speed from leg or schedule
    //     let wpSpeed = null;
    //     if (wpLeg) {
    //       const {
    //         $: { speedMin: speedMinStr, speedMax: speedMaxStr },
    //       } = wpLeg;
    //       wpSpeed = (parseFloat(speedMinStr) + parseFloat(speedMaxStr)) / 2;
    //     } else if (!ignoreSchedule && schedule) {
    //       const scheduleElement = schedule.find((element) => element.$.id === wpId);
    //       if (scheduleElement) {
    //         wpSpeed = parseFloat(scheduleElement.$.speed);
    //       }
    //     }

    //     // Create and add RoutePoint
    //     const routePoint = new RoutePoint(
    //       parseFloat(waypoint.$.latitude),
    //       parseFloat(waypoint.$.longitude),
    //       wpSpeed,
    //       wpRadius,
    //       wpPortXte,
    //       wpStbdXte,
    //     );
    //     routePoints.push(routePoint);
    //   });
    // });

    // return routePoints;
  // }

// class RoutePoint {
//   constructor(latitude, longitude, speed, radius, leftXte, rightXte) {
//     this.latitude = latitude;
//     this.longitude = longitude;
//     this.speed = speed;
//     this.radius = radius;
//     this.leftXte = leftXte;
//     this.rightXte = rightXte;
//   }
// }




// class RtzConverter {
//   static MAX_WP_RADIUS = 5.0;
//   static MAX_LEG_STD = 3.0;
//   static MAX_LEG_SPEED = 30.0;

//   static convertRtzRouteToRoutePoints(rtzRoute, ignoreSchedule = false) {
//     const routePoints = [];

//     const parseXml = (xmlString) => new Promise((resolve, reject) => {
//       xml2js.parseString(xmlString, (err, result) => {
//         if (err) {
//           reject(err);
//         } else {
//           resolve(result);
//         }
//       });
//     });

//     parseXml(rtzRoute).then((result) => {
//       const { waypoints: { waypoint: waypoints } } = result[`${RTZ_NAMESPACE}:waypoints`];
//       const { defaultWaypoint: defaultWaypoint } = result[`${RTZ_NAMESPACE}:waypoints`];
//       const { schedule: schedule } = result[`${RTZ_NAMESPACE}:schedule`];

//       // Extract default values
//       let defaultRadius = null;
//       let defaultPortXte = null;
//       let defaultStbdXte = null;

//       if (defaultWaypoint) {
//         const {
//           $: { radius: defaultRadiusStr, portsideXTD: defaultPortXteStr, starboardXTD: defaultStbdXteStr },
//         } = defaultWaypoint;
//         defaultRadius = parseFloat(defaultRadiusStr);
//         defaultPortXte = parseFloat(defaultPortXteStr);
//         defaultStbdXte = parseFloat(defaultStbdXteStr);
//       }

//       waypoints.forEach((waypoint) => {
//         const {
//           $: { id: wpId, radius: wpRadiusStr, portsideXTD: wpPortXteStr, starboardXTD: wpStbdXteStr },
//           leg: wpLeg,
//         } = waypoint;

//         // Extract waypoint data
//         const wpRadius = parseFloat(wpRadiusStr) || defaultRadius;
//         const wpPortXte = parseFloat(wpPortXteStr) || defaultPortXte;
//         const wpStbdXte = parseFloat(wpStbdXteStr) || defaultStbdXte;

//         // Extract speed from leg or schedule
//         let wpSpeed = null;
//         if (wpLeg) {
//           const {
//             $: { speedMin: speedMinStr, speedMax: speedMaxStr },
//           } = wpLeg;
//           wpSpeed = (parseFloat(speedMinStr) + parseFloat(speedMaxStr)) / 2;
//         } else if (!ignoreSchedule && schedule) {
//           const scheduleElement = schedule.find((element) => element.$.id === wpId);
//           if (scheduleElement) {
//             wpSpeed = parseFloat(scheduleElement.$.speed);
//           }
//         }

//         // Create and add RoutePoint
//         const routePoint = new RoutePoint(
//           parseFloat(waypoint.$.latitude),
//           parseFloat(waypoint.$.longitude),
//           wpSpeed,
//           wpRadius,
//           wpPortXte,
//           wpStbdXte,
//         );
//         routePoints.push(routePoint);
//       });
//     });

//     return routePoints;
//   }
// }

// export default RtzConverter;
