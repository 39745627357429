export const format = (num, label = "") => {
    let ending = label === "" ? "" : " " + label;
    return !isNaN(num)
        ? Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K" + ending
            : Math.sign(num) * Math.abs(num).toFixed(2) + ending
        : "N/A";
};

export const formatCoordinates = (lat) => {
    // let degreeLat =  Math.abs(Math.trunc(lat));

    // console.log("lat", lat)
    let minLat = (lat - Math.trunc(lat)) * 100;

    // console.log("minLat", minLat)
    let secLat = (minLat - Math.trunc(minLat)) * 1000;

    // console.log("secLat", secLat)

    minLat = (minLat > 0) ? minLat : (minLat * -1);
    secLat = (secLat > 0) ? secLat : (secLat * -1);

    return (lat > 100 ? " " : "") + (Math.trunc(lat) == 0 && lat < 0 ? "-" : "")  + Math.trunc(lat) + "°" + Math.trunc(minLat) + "," + Math.trunc(secLat) + '"';

};