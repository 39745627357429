const state = {
  details: {},
};

const getters = {};
const actions = {};

const mutations = {
  setDetails(state, details) {
    state.details = details;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
