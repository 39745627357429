const state = {
  noonReports: [],
  eventsNoonReports: [],
};

const getters = {};
const actions = {};

const mutations = {
  noonReports(state, data) {
    state.noonReports = data;
  },
  eventsNoonReports(state, data) {
    state.eventsNoonReports = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
