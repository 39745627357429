import createAuthRefreshInterceptor from 'axios-auth-refresh';
import router from '@/router';

export default (app) => {
  let { store, axios } = app;

  axios.interceptors.request.use((request) => {
    request.headers['Authorization'] = `Bearer ${store.state.auth.token}`;

    return request;
  });

  const refreshAuthLogic = (request) =>
    axios
      .post('/api/auth/refresh')
      .then(({ data }) => {
        store.commit('auth/setToken', data.access_token);

        request.response.config.headers[
          'Authorization'
        ] = `Bearer ${data.access_token}`;

        return Promise.resolve();
      })
      .catch(() => {
        store.commit('auth/setUser', null);
        store.commit('auth/setToken', null);

        window.location.reload();
      });

  createAuthRefreshInterceptor(axios, refreshAuthLogic, {
    pauseInstanceWhileRefreshing: true,
  });

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error instanceof axios.Cancel) {
        return Promise.reject(error);
      }

      const { status } = error.response;

      if (status == 403) {
        store.commit('auth/setUser', null);
        store.commit('auth/setToken', null);

        window.location.reload();
      }

      if (status >= 500) {
        this.$root.$emit('error', error.response.data.message);
      }

      return Promise.reject(error);
    }
  );

  router.beforeEach((to, _, next) => {
    let middleware = to.meta.middleware;
    let isLoggedIn = store.state.auth.token;
    let dashboard = store.state.auth.user?.dashboard ?? 'fip';

    if (!to.path.startsWith('/' + dashboard) && isLoggedIn) {
      return next({ name: 'Overview' });
    }

    if (middleware === 'guest' && isLoggedIn) {
      return next({ name: 'Overview' });
    }

    if (middleware === 'auth' && !isLoggedIn) {
      if (to.path === '/auth/login') {
        return next({ name: 'Login' });
      }

      return next({ name: 'Login', params: { redirect: to.name } });
    }

    if (to.path == '/') {
      return next({ name: 'Overview' });
    }

    next();
  });
};
