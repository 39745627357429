const state = {
  details: [],
  data_sources: [],
};

const getters = {};
const actions = {};

const mutations = {
  setDetails(state, details) {
    state.details = details;
  },
  setDataSources(state, data) {
    state.data_sources = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
