<template>
  <div class="header-accounts">
    <a-dropdown
      overlayClassName="dropdown-header-accounts"
      :trigger="['click']"
    >
      <a class="ant-dropdown-link" @click.prevent>
        <div class="header-accounts-selected">
          <a-typography-text type="secondary">{{$t('header.fleet')}}</a-typography-text>
          <a-typography-text>DEMO</a-typography-text>
        </div>

        <DownOutlined />
      </a>

      <template #overlay>
        <a-menu>
          <a-menu-item key="0">
            <a-typography-text>DEMO</a-typography-text>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import { DownOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "HeaderAccounts",

  setup() {
    return {};
  },

  components: {
    DownOutlined,
  },
});
</script>

<style lang="less" scoped>
.header-accounts {
  .ant-dropdown-link {
    height: 5.4rem;
    display: flex;
    align-items: center;
    padding: 0.7rem 1.5rem;
    gap: 1.2rem;
    position: relative;

    &:after,
    &:before {
      content: "";
      width: 0.1rem;
      height: calc(5.4rem - 1.2rem);
      //background-color: @color-border;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &:after {
      left: 0;
    }

    &:before {
      right: 0;
    }

    &.ant-dropdown-open {
      background-color: #212125;

      &:after,
      &:before {
        background-color: #212125;
      }
    }

    .anticon {
      color: @color-secondary;
      font-size: 1.3rem;
    }
  }

  &-selected {
    height: 100%;
    display: flex;
    flex-direction: column;

    .ant-typography {
      line-height: 1.4;

      &:not(.ant-typography-secondary) {
        color: @color-white;
        text-transform: uppercase;
      }
    }

    .ant-typography-secondary {
      color: #666971;
    }
  }
}
</style>
