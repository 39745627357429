import { useStore } from 'vuex';
import { useRouter } from "vue-router";

import axios from 'axios';

export default () => {
  const store = useStore();
  const router = useRouter();

  const login = async (data) => {
    let UserForm = new FormData();

    UserForm.append('username', data.username);
    UserForm.append('password', data.password);

    return axios.post('/api/auth/login', UserForm).then(({ data }) => {
      console.log(data.access_token);

      store.commit('auth/setToken', data.access_token);
    });
  };

  const logout = () => {
    store.commit('auth/setUser', {});
    store.commit('auth/setToken', null);

    store.commit('dashboard/setDetails', []);
    store.commit('dashboard/setDataSources', []);

    store.commit('fleetBenchmark/setFleets', []);
    store.commit('fleetBenchmark/setPorts', []);

    store.commit('reports/noonReports', []);

    store.commit('vessels/setDetails', {});
    store.commit('vessels/setList', []);

    store.commit('voyages/setDetails', {});
    store.commit('voyages/setList', []);

    localStorage.removeItem('vuex');

    router.push({ name: "Login" });
  };

  const fetch = () => {
    return axios.get('/api/auth/user').then(({ data }) => {
      store.commit('auth/setUser', data);

      return data;
    });
  };

  const user = () => {
    return store.state.auth.user;
  };

  return {
    logout,
    login,
    fetch,
    user,
  };
};
