<template>
  <a-layout class="base-layout">
    <Header />

    <a-layout-content>
      <router-view />
    </a-layout-content>
  </a-layout>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseLayout",
});
</script>

<style lang="less" scoped>
.base-layout {
  padding-top: 5.4rem;
}
</style>
