<template>
  <div class="timeline-chart">
    <div class="timeline-chart-container">
      <a-select
        placeholder="RPM Plan & Fact"
        style="width: 174px"
        v-model:value="selectedType.type"
      >
        <a-select-option value="RPM">RPM Plan & Fact</a-select-option>
      </a-select>

      <div class="timeline-chart-content">
        <vue-echarts
          ref="chartRef"
          :option="chartOption"
          :style="`height: 310px`"
          autoresize
        />
      </div>
    </div>

    <div class="timeline-chart-details">
      <div class="timeline-chart-title">
        <a-typography-title :level="3">Alpha Bravo</a-typography-title>
      </div>

      <div class="timeline-chart-inner">
        <div class="timeline-chart-type">
          <div class="timeline-chart-type-inner">
            <a-typography-text type="secondary">#AB001</a-typography-text>
            <a-typography-text type="secondary">Laden</a-typography-text>
          </div>
        </div>

        <div class="timeline-chart-direction">
          <a-typography-text strong>CN SHG</a-typography-text>
          <span class="timeline-chart-direction-arrow" />
          <a-typography-text strong>CN XMG</a-typography-text>
        </div>

        <div class="timeline-chart-dates">
          <div class="timeline-chart-dates-item">
            <a-typography-text type="secondary">ATD</a-typography-text>
            <a-typography-text>12 Jan, 12:00</a-typography-text>
          </div>

          <div class="timeline-chart-dates-item align-right">
            <a-typography-text type="secondary">ETA</a-typography-text>
            <a-typography-text>16 Jan, 21:11</a-typography-text>
          </div>
        </div>
      </div>

      <div class="timeline-chart-coords">
        <a-space align="center" size="large">
          <a-typography-text type="secondary">COG</a-typography-text>
          <a-typography-text>078°</a-typography-text>
        </a-space>

        <a-space align="center" size="large">
          <a-typography-text type="secondary">SOG</a-typography-text>
          <a-typography-text>12.2 kn</a-typography-text>
        </a-space>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, computed } from "vue";
import { VueEcharts } from "vue3-echarts";

export default defineComponent({
  name: "TimelineChart",

  setup() {
    const chartRef = ref(null);

    const selectedType = reactive({ type: "RPM" });

    const chart = reactive({
      option: {
        grid: {
          top: "8px",
          left: "0",
          right: "0",
          bottom: "0",
        },
        xAxis: {
          data: [0, 2, 3, 4, 5, 6, 7, 8],
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
        yAxis: {
          boundaryGap: true,
          axisLine: { onZero: false },
          axisLabel: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              color: "rgba(75,76,83, 0.5)",
            },
          },
        },
        series: [
          {
            type: "line",
            symbolSize: 0,
            lineStyle: {
              color: "#FDEDB6",
              type: "dashed",
              width: 1,
            },
            data: [0, 10, 11, 13, 11, 12, 12, 9],
          },
          {
            type: "line",
            symbolSize: 0,
            lineStyle: {
              color: "#7F8DC1",
              width: 1,
            },
            data: [1, 11, 2, 7, 5, 7],
            markLine: {
              symbol: ["none", "none"],
              label: { show: false },
              lineStyle: {
                color: "#fff",
                type: "dashed",
                width: 3,
              },
              data: [{ xAxis: 5 }],
            },
          },
        ],
      },
    });

    const chartOption = computed(() => chart.option);

    return {
      chartOption,
      chartRef,
      selectedType,
    };
  },

  components: {
    VueEcharts,
  },
});
</script>

<style lang="less" scoped>
.timeline-chart {
  width: 100%;
  height: 32.6rem;
  background-color: @color-black;
  display: flex;
  position: absolute;
  bottom: 5rem;
  left: 0;
  z-index: 1;

  &-container {
    padding: 0.8rem 0 0.8rem 0.8rem;
    flex: 1;

    &:deep(.ant-select) {
      position: absolute;
      top: 2rem;
      left: 2rem;
      z-index: 1;

      &-selector {
        background-color: @color-black;
        border-color: transparent;
      }
    }
  }

  &-content {
    background-color: @color-body;
  }

  &-details {
    width: 28rem;
  }

  &-title {
    border-bottom: 0.1rem solid @color-border;
    padding: 2rem 2.4rem;

    h3.ant-typography {
      font-size: 2rem;
      font-weight: 500;
      margin-top: 0.8rem;
    }
  }

  &-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 0.1rem solid @color-border;
    padding: 1.4rem 2.4rem;
  }

  &-type {
    border: 0.1rem solid @color-border;
    border-radius: 0px;
    margin-bottom: 0.6rem;
    position: relative;

    &:before {
      content: "";
      width: 2.4rem;
      height: 2.4rem;
      background-color: @color-body;
      border: 0.1rem solid @color-border;
      border-radius: 0px;
      position: absolute;
      top: 1rem;
      left: calc(50% - 1.2rem);
      transform: rotate(45deg);
    }

    &-inner {
      background-color: @color-body;
      border-radius: 0px;
      padding: 0.4rem 1rem;
      display: flex;
      align-items: center;
      gap: 1.2rem;
      position: relative;
      z-index: 1;
    }
  }

  &-direction {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.8rem;
    margin-bottom: 1rem;

    &-arrow {
      width: 100%;
      border-bottom: 0.15rem solid @color-white;
      position: relative;

      &:before {
        content: "";
        width: 0.8rem;
        height: 0.8rem;
        border-style: solid;
        border-width: 0.15rem 0.15rem 0 0;
        display: inline-block;
        position: absolute;
        top: -0.325rem;
        right: 0;
        transform: rotate(45deg);
      }
    }

    .ant-typography {
      font-size: 1.6rem;
      white-space: nowrap;
    }
  }

  &-dates {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-item {
      display: flex;
      flex-direction: column;

      &.align-right {
        text-align: right;
      }
    }
  }

  &-coords {
    display: flex;
    flex-direction: column;
    padding: 1.4rem 2.4rem;
  }
}
</style>
