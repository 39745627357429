import Header from './header/Header';
import Shape from './Shape';
import PercentDirection from './PercentDirection';
import ModalNoonReport from './ModalNoonReport';
import TimelineChart from './TimelineChart';

const components = [Header, Shape, PercentDirection, ModalNoonReport, TimelineChart];

export default {
  install(app) {
    components.map((component) => {
      app.component(component.name, component);
    });
  },
};
