<template>
  <div :class="['shape', { 'is-large': isLarge }]">
    <svg
      width="46"
      height="25"
      viewBox="0 0 46 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0H40.3902L46 12.5L40.3902 25H0L5.60976 12.5L0 0Z"
        :fill="background"
      />

      <text
        x="50%"
        y="55%"
        dominant-baseline="middle"
        text-anchor="middle"
        :fill="color"
      >
        {{ text }}
      </text>
    </svg>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Shape",

  props: {
    isLarge: {
      type: Boolean,
      default: false,
    },
    background: String,
    color: {
      type: String,
      default: "#FFFFFF",
    },
    text: String,
  },

  setup() {
    return {};
  },
});
</script>

<style lang="less" scoped>
.shape {
  height: 2.5rem;

  text {
    font-size: 1.6rem;
  }

  &.is-large {
    height: 5.1rem;
    font-size: 4rem;

    svg {
      width: 8.5rem;
      height: 5.1rem;
    }
  }
}
</style>



  