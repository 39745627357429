import { fromUnixTime, parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { useI18n } from 'vue-i18n';

const initialDateFormat = 'yyyy-MM-dd HH:mm:ss';

export const formatUTC = (dateString, ft = initialDateFormat) => {
  const parsedTime = parseISO(dateString);

  return format(utcToZonedTime(parsedTime, 'UTC'), ft, { timeZone: 'UTC' });
};

export const formatDateFromMinutes = (minutesRaw) => {

  const hoursraw = Math.trunc( minutesRaw/60 )  
  const days = Math.trunc( hoursraw /24 )
  const minutes = minutesRaw - (hoursraw * 60)
  const hours =  hoursraw - ( days * 24 )  
  const { t } = useI18n();

  return (days > 0 ? (days + " " + t('units.days') + " ") : "") + hours + " " + t('units.hrs') + " " + (minutes> 0 ? (minutes + " " + t('units.min')) : "");
}

export const formatFromUnixUTC = (dateUnix, ft = initialDateFormat) => {
  const formattedDate = (ftUnix) =>
    format(new Date(fromUnixTime(dateUnix)), ftUnix);

  return formatUTC(
    `${formattedDate('yyyy-MM-dd')}T${formattedDate('HH:mm:ss')}`,
    ft
  );
};
