import { createLogger, createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import app from './modules/app';
import dashboard from './modules/dashboard';
import fleetBenchmark from './modules/fleetBenchmark';
import vessel from './modules/vessel';
import vessels from './modules/vessels';
import voyages from './modules/voyages';
import routeOptimization from './modules/routeOptimization';
import reports from './modules/reports';
import deliveries from './modules/deliveries';
import auth from './modules/auth';

const debug = process.env.NODE_ENV !== 'production';

const dataState = createPersistedState({
  paths: ['auth'],
});

const store = createStore({
  modules: {
    app,
    dashboard,
    fleetBenchmark,
    vessel,
    vessels,
    voyages,
    routeOptimization,
    reports,
    deliveries,
    auth,
  },
  strict: debug,
  plugins: debug ? [createLogger(), dataState] : [dataState],
});

export default (app) => {
  app.store = store;
  app.use(store);
};
